import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { Accordion, Button, Card } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faSkull } from '@fortawesome/free-solid-svg-icons';
import { Seo } from '../../modules/common/components/seo';
import { SectionHeader } from '../../modules/common/components/section-header';

import lodash from 'lodash';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

interface IBCMCharacterNodes {
  nodes: IBCMCharacter[];
}

interface IBCMCharacterEntry {
  allCharacters: IBCMCharacterNodes;
}

interface IProps {
  data: IBCMCharacterEntry;
}

import './tier-list.scss';
import { IBCMCharacter } from '../../modules/common/model/graphql-types';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { BCMCharacter } from '../../modules/bcm/common/components/bcm-character';

const BCMTierListPage: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'R',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/grade_r.png"
                height={24}
                alt="R rarity"
              />
            )
          },
          {
            value: 'SR',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/grade_sr.png"
                height={24}
                alt="SR rarity"
              />
            )
          },
          {
            value: 'SSR',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/grade_ssr.png"
                height={24}
                alt="SSR rarity"
              />
            )
          }
        ]
      },
      {
        key: 'attribute',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Power',
            tooltip: 'Power',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/atr_power.png"
                width={24}
                alt="Power"
              />
            )
          },
          {
            value: 'Sense',
            tooltip: 'Sense',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/atr_sense.png"
                width={24}
                alt="Sense"
              />
            )
          },
          {
            value: 'Technique',
            tooltip: 'Technique',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/atr_tech.png"
                width={24}
                alt="Technique"
              />
            )
          },
          {
            value: 'Harmony',
            tooltip: 'Harmony',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/atr_harmony.png"
                width={24}
                alt="Harmony"
              />
            )
          },
          {
            value: 'Chaos',
            tooltip: 'Chaos',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/atr_chaos.png"
                width={24}
                alt="Chaos"
              />
            )
          }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Attacker',
            tooltip: 'Attacker',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/class_attacker.png"
                width={24}
                alt="Attacker"
              />
            )
          },
          {
            value: 'Debuffer',
            tooltip: 'Debuffer',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/class_debuffer.png"
                width={24}
                alt="Debuffer"
              />
            )
          },
          {
            value: 'Defender',
            tooltip: 'Defender',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/class_defender.png"
                width={24}
                alt="Defender"
              />
            )
          },
          {
            value: 'Healer',
            tooltip: 'Healer',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/class_healer.png"
                width={24}
                alt="Healer"
              />
            )
          },
          {
            value: 'Supporter',
            tooltip: 'Supporter',
            image: (
              <StaticImage
                src="../../images/blackclover/icons/class_supporter.png"
                width={24}
                alt="Supporter"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.attribute && activeFilters.attribute !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.attribute.toLowerCase() ===
            activeFilters.attribute.toLowerCase()
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
    }

    if (sortOption) {
      filtered = filtered.sort((a, b) =>
        a[sortOption] < b[sortOption] ? 1 : -1
      );
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: 'pvp', label: 'PVP' },
    { value: 'pve', label: 'PVE' }
  ];

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    // eslint-disable-next-line prefer-destructuring
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout
      className={'generic-page characters-page-nikke bcm-tier'}
      game="bcm"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/black-clover/">Black Clover M</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier list</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/blackclover/categories/cat_tier.png"
            alt="Tier list"
          />
        </div>
        <div className="page-details">
          <h1>Black Clover Mobile Tier list</h1>
          <h2>Tier list for Black Clover Mobile that rates all characters.</h2>
          <p>
            Last updated: <strong>30/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      <div className="tier-list-page nikke">
        <SectionHeader title="Tier List" />
        <div className="employees-filter-bar bcm">
          <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
        </div>
        <Accordion defaultActiveKey="0" className="tier-list-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>About the tier list</Accordion.Header>
            <Accordion.Body>
              <p>
                The tier list now features one unified PVE and PVP rating for
                both JPN and Global servers. The PVE/PVP split inside the server
                tier list remained. I would like to thank the community for the
                help with creating and maintaining the tier list, especially the{' '}
                <strong>Nacht's Hideout</strong> alliance (if you want to join
                them, here's their Discord{' '}
                <a
                  href="https://discord.gg/2eQda5kPtc"
                  target="_blank"
                  rel="noreferrer"
                >
                  server
                </a>
                ) and <strong>WIZARDS JP</strong>.
              </p>
              <p>The tier list rates characters at 5 dupes and level 125.</p>
              <p>Special marks explained:</p>
              <ul>
                <li>
                  <span className="arrow-purple">S?</span> - the season the
                  character was released in,
                </li>
                <li>
                  <FontAwesomeIcon
                    icon={faSkull}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  - character who is a single-target specialist, shining against
                  Bosses in PVE.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Changelog</Accordion.Header>
            <Accordion.Body>
              <p>
                <strong>30.09.2024</strong>
              </p>
              <p>
                Removed the split between Global and KR and updated the PVP tier
                list. The PVE tier list will be updated soon!
              </p>
              <p>
                <strong>11.08.2024</strong>
              </p>
              <p>Updated the JPN PVE tier list.</p>
              <p>
                <strong>08.08.2024</strong>
              </p>
              <p>
                Fully reworked the JPN/KR tier list for PVP. Other tier lists
                will follow soon!
              </p>
              <p>
                <strong>08.07.2024</strong>
              </p>
              <p>Updated the JPN PVP tier list.</p>
              <p>
                <strong>25.06.2024</strong>
              </p>
              <p>Added Noelle [Valkyrie Dress] to the Global tier list.</p>
              <p>
                <strong>14.06.2024</strong>
              </p>
              <p>Updated the JPN PVP tier list.</p>
              <p>
                <strong>20.05.2024</strong>
              </p>
              <p>Updated Global and JPN tier lists.</p>
              <p>
                <strong>06.05.2024</strong>
              </p>
              <ul>
                <li>
                  Updated the Global tier list with Vanessa [Red Thread of Fate]
                  and Zora Ideale units.
                </li>
              </ul>
              <p>
                <strong>02.05.2024</strong>
              </p>
              <ul>
                <li>Updated the Global tier list with Party units.</li>
              </ul>
              <p>
                <strong>25.04.2024</strong>
              </p>
              <ul>
                <li>KR PVE tier list has been updated.</li>
              </ul>
              <p>
                <strong>16.04.2024</strong>
              </p>
              <ul>
                <li>
                  Updated the KR/JPN PVP and PVE tier list with Kahono [Party]
                  and Nozel [Party].
                </li>
              </ul>
              <p>
                <strong>15.04.2024</strong>
              </p>
              <ul>
                <li>
                  Updated the KR/JPN PVP and PVE tier list with new characters,
                </li>
                <li>
                  Updated the GLobal PVP list with Mereoleona Vermillion and her
                  impact on the PVP meta.
                </li>
              </ul>
              <p>
                <strong>01.04.2024</strong>
              </p>
              <ul>
                <li>
                  Updated the KR/JPN PVP tier list. The PVE one will be updated
                  soon!
                </li>
                <li>
                  Spirit Dive Yuno will also be added to the tier list in the
                  next few days.
                </li>
              </ul>
              <p>
                <strong>30.03.2024</strong>
              </p>
              <ul>
                <li>
                  Added Luck [Ceremony] and Yami [Ceremony] to Global tier list,
                </li>
                <ul>
                  <li>The KR tier list is coming soon!</li>
                </ul>
              </ul>
              <p>
                <strong>10.03.2024</strong>
              </p>
              <ul>
                <li>
                  Added Fana [Hateful] and Queen of Witches to Global tier list,
                </li>
                <li>
                  The Global PVE and PVP tier lists have been fully reworked to
                  adjust them to the current Global meta.
                </li>
              </ul>
              <p>
                <strong>04.03.2024</strong>
              </p>
              <ul>
                <li>
                  Added Vanessa [Swimsuit], Magna [Ceremony] and Charlotte
                  [Ceremony] to Global tier list.
                </li>
              </ul>
              <p>
                <strong>24.02.2024</strong>
              </p>
              <ul>
                <li>Added Gueldre and Despair Vetto to Global tier list.</li>
              </ul>
              <p>
                <strong>07.02.2024</strong>
              </p>
              <ul>
                <li>
                  Made more changes to the KR PVP tier list to better reflect
                  Mareoleona impact on the meta.
                </li>
                <ul>
                  <li>
                    The biggest victim of hers seems to be Julius who she
                    counters with her own meta team and all other Blue units who
                    were both pushed down by him and countered by the emerging
                    Green faction meta (Mere + Fana + Charlotte [Ceremony] +
                    Gueldre Poizot is a great Green mono team),
                  </li>
                  <li>
                    Mere + School Fuego is also a popular variant, but there you
                    need make sure Fuego Ultimate will wipe the board - because
                    he removes the Burns after the attack,
                  </li>
                  <li>
                    There's also a Red meta team with Black Asta + Charmy
                    [Halloween] + Yuno [Golden Dawn] + Queen of the Witches -
                    it's a whale team where you need everyone LR+5 and great
                    gear, but if you achieve that, there's no counter.
                  </li>
                </ul>
                <li>
                  Added Black Asta to the Global tier list. Sadly, without Witch
                  Queen he won't be as strong as in KR/JPN as she's the key to
                  unlocking his meta team.
                </li>
              </ul>
              <p>
                <strong>04.02.2024</strong>
              </p>
              <ul>
                <li>
                  Added Mereoleona Vermillion to the KR tier list and adjusted a
                  lot of other ratings to fit the new PVP meta.
                </li>
              </ul>
              <p>
                <strong>01.02.2024</strong>
              </p>
              <ul>
                <li>
                  Added Charmy [Halloween] and Noelle [Halloween] to the Global
                  tier list.
                </li>
              </ul>
              <p>
                <strong>23.01.2024</strong>
              </p>
              <ul>
                <li>
                  Added Yuno [Golden Dawn] and Langris Vaude to the Global tier
                  list.
                </li>
              </ul>
              <p>
                <strong>12.01.2024</strong>
              </p>
              <ul>
                <li>
                  Added Season 3 characters to the Global tier list - Fuegoleon
                  [Clover Academy], Charlotte [Clover Academy], Jack [Clover
                  Academy],
                </li>
                <li>
                  Added Season 8 characters to the KR/JPN tier list - Yami
                  [Ceremony] and Luck [Ceremony],
                </li>
                <li>
                  Updated the KR/JPN tier lists to include the meta shift. The
                  main change is Black Asta jumping to the highest tier (in both
                  PVE and PVP) as he simply dominates the game and Summer Noelle
                  dropping a tier in PVP as there's too many counters to her
                  nowadays.
                </li>
              </ul>
              <p>
                <strong>01.01.2024</strong>
              </p>
              <ul>
                <li>Added Kiato and Kahono to the Global tier list.</li>
              </ul>
              <p>
                <strong>23.12.2023</strong>
              </p>
              <ul>
                <li>Added Julius to the Global tier list,</li>
                <li>Added Black Asta to the KR/JPN tier list.</li>
              </ul>
              <p>
                <strong>17.12.2023</strong>
              </p>
              <ul>
                <li>
                  Added Summer Asta to the tier list - in Global he is a free
                  character that everyone can obtain from the upcoming event,
                </li>
              </ul>
              <p>
                <strong>14.12.2023</strong>
              </p>
              <ul>
                <li>Season 2 Global characters were added to the tier list,</li>
                <li>
                  It seems that compared to KR/JPN the Season 2 roster had
                  changed a bit and instead of Summer Yuno we received Summer
                  Charmy who was part of Season 3 in KR/JPN,
                </li>
                <li>
                  Because of how strong Summer Noelle is, a lot of the S+ tier
                  characters have dropped a tier - she's simply too strong in
                  PVP and no current characters can counter her in PVP (the
                  Summer Noelle with more dupes/better gear will simply win) and
                  as for PVE, she's making everything easier there too.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="banner bcm-promo">
          <h4>Play Black Clover Mobile on PC</h4>
          <OutboundLink
            href="https://www.ldplayer.net/games/black-clover-m-on-pc.html?n=82774712#utm_source=aff&utm_medium=aff&utm_campaign=aff82774712"
            target="_blank"
          >
            <Button variant="primary">Play now on LDPlayer</Button>
          </OutboundLink>
        </div>
        <div className="tier-list-header">
          <p className="title">
            Tier List{' '}
            <span>
              ({sortOption === 'pve' && 'PVE'}
              {sortOption === 'pvp' && 'PVP'})
            </span>
          </p>
          <p className="sub-title">
            You're currently viewing the{' '}
            <strong>
              {sortOption === 'pve' && 'PVE'}
              {sortOption === 'pvp' && 'PVP'}
            </strong>{' '}
            tier list. It shows{' '}
            {sortOption === 'pve' &&
              'how a character performs in Story and Raid content.'}
            {sortOption === 'pvp' && 'how a character performs in PVP content.'}
          </p>
        </div>
        <div className="tier-list-switcher">
          <div
            className={`option pvp ${sortOption === 'pvp' && 'selected'}`}
            onClick={() => setSortOption('pvp')}
          >
            <p>PVP</p>
          </div>
          {/* <div
            className={`option kr_pve ${sortOption === 'kr_pve' && 'selected'}`}
            onClick={() => setSortOption('kr_pve')}
          >
            <p>PVE</p>
          </div> */}
          <div className={`option pve ${sortOption === 'pve' && 'selected'}`}>
            <p>PVE (Coming Soon)</p>
          </div>
        </div>
        <div className="custom-tier-list-bcm">
          <div className="custom-tier-header">
            <div className="tier-rating">
              <span>&nbsp;</span>
            </div>
            <div className="custom-tier-container">
              <div className="custom-header power">Power</div>
              <div className="custom-header sense">Sense</div>
              <div className="custom-header technique">Technique</div>
              <div className="custom-header harmony">Harmony</div>
              <div className="custom-header chaos">Chaos</div>
            </div>
          </div>
          <div className="custom-tier tier-s-plus first">
            <div className="tier-rating s-plus">
              <span>S+</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile power">Power</div>
              <div className="custom-tier-burst power">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9.5')
                  .filter((emp) => emp.attribute === 'Power')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sense">Sense</div>
              <div className="custom-tier-burst sense">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9.5')
                  .filter((emp) => emp.attribute === 'Sense')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile technique">Technique</div>
              <div className="custom-tier-burst technique">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9.5')
                  .filter((emp) => emp.attribute === 'Technique')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile harmony">Harmony</div>
              <div className="custom-tier-burst harmony">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9.5')
                  .filter((emp) => emp.attribute === 'Harmony')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile chaos">Chaos</div>
              <div className="custom-tier-burst chaos">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9.5')
                  .filter((emp) => emp.attribute === 'Chaos')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-s">
            <div className="tier-rating s">
              <span>S</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile power">Power</div>
              <div className="custom-tier-burst power">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.attribute === 'Power')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sense">Sense</div>
              <div className="custom-tier-burst sense">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.attribute === 'Sense')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile technique">Technique</div>
              <div className="custom-tier-burst technique">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.attribute === 'Technique')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile harmony">Harmony</div>
              <div className="custom-tier-burst harmony">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.attribute === 'Harmony')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile chaos">Chaos</div>
              <div className="custom-tier-burst chaos">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '9')
                  .filter((emp) => emp.attribute === 'Chaos')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="fuse-ad-placeholder" data-fuse="22844297007"></div>
          <div className="custom-tier tier-a">
            <div className="tier-rating a">
              <span>A</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile power">Power</div>
              <div className="custom-tier-burst power">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.attribute === 'Power')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sense">Sense</div>
              <div className="custom-tier-burst sense">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.attribute === 'Sense')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile technique">Technique</div>
              <div className="custom-tier-burst technique">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.attribute === 'Technique')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile harmony">Harmony</div>
              <div className="custom-tier-burst harmony">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.attribute === 'Harmony')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile chaos">Chaos</div>
              <div className="custom-tier-burst chaos">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '8')
                  .filter((emp) => emp.attribute === 'Chaos')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-b">
            <div className="tier-rating b">
              <span>B</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile power">Power</div>
              <div className="custom-tier-burst power">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.attribute === 'Power')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sense">Sense</div>
              <div className="custom-tier-burst sense">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.attribute === 'Sense')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile technique">Technique</div>
              <div className="custom-tier-burst technique">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.attribute === 'Technique')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile harmony">Harmony</div>
              <div className="custom-tier-burst harmony">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.attribute === 'Harmony')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile chaos">Chaos</div>
              <div className="custom-tier-burst chaos">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '7')
                  .filter((emp) => emp.attribute === 'Chaos')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-c">
            <div className="tier-rating c">
              <span>C</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile power">Power</div>
              <div className="custom-tier-burst power">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.attribute === 'Power')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sense">Sense</div>
              <div className="custom-tier-burst sense">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.attribute === 'Sense')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile technique">Technique</div>
              <div className="custom-tier-burst technique">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.attribute === 'Technique')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile harmony">Harmony</div>
              <div className="custom-tier-burst harmony">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.attribute === 'Harmony')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile chaos">Chaos</div>
              <div className="custom-tier-burst chaos">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '6')
                  .filter((emp) => emp.attribute === 'Chaos')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-d">
            <div className="tier-rating d">
              <span>D</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile power">Power</div>
              <div className="custom-tier-burst power">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.attribute === 'Power')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sense">Sense</div>
              <div className="custom-tier-burst sense">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.attribute === 'Sense')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile technique">Technique</div>
              <div className="custom-tier-burst technique">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.attribute === 'Technique')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile harmony">Harmony</div>
              <div className="custom-tier-burst harmony">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.attribute === 'Harmony')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile chaos">Chaos</div>
              <div className="custom-tier-burst chaos">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '5')
                  .filter((emp) => emp.attribute === 'Chaos')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-e">
            <div className="tier-rating e">
              <span>E</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile power">Power</div>
              <div className="custom-tier-burst power">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.attribute === 'Power')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile sense">Sense</div>
              <div className="custom-tier-burst sense">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.attribute === 'Sense')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile technique">Technique</div>
              <div className="custom-tier-burst technique">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.attribute === 'Technique')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile harmony">Harmony</div>
              <div className="custom-tier-burst harmony">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.attribute === 'Harmony')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile chaos">Chaos</div>
              <div className="custom-tier-burst chaos">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === '4')
                  .filter((emp) => emp.attribute === 'Chaos')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <BCMCharacter
                            slug={emp.slug}
                            mode="icon"
                            showLabel
                            showIcon
                            enablePopover
                            enableTag
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297007"></div>
      </div>
    </DashboardLayout>
  );
};

export default BCMTierListPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier list | Black Clover Mobile | Prydwen Institute"
    description="Tier list for Black Clover Mobile that rates all characters both in PVE and PVP!"
    game="bcm"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulBlackCloverCharacter(
      sort: { fields: slug, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        squad
        attribute
        class
        rarity
        isCharacterSeasonal
        season
        ratings {
          pve
          pvp
        }
      }
    }
  }
`;
